import { ProductEnvironment } from '@atlassian/forge-ui-types';

import type { Extension } from '../types';

const capitalize = (str: string): string => {
	if (!str || !str.length) {
		return '';
	}
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const extensionTitle = (extension: Extension, overriddenTitle?: string | null): string => {
	const title = overriddenTitle || extension.properties.title;
	const { environmentType, environmentKey } = extension;

	if (environmentType === ProductEnvironment.PRODUCTION) {
		return title;
	}

	if (environmentType === ProductEnvironment.DEVELOPMENT && environmentKey !== 'default') {
		return `${title} - ${environmentKey} (Dev)`;
	}

	return `${title} (${capitalize(environmentType)})`;
};
