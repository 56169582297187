import { cloneElement, PureComponent, type ReactNode } from 'react';

import type { CoordinationClientType } from '../coordination-client';

type Props = {
	fallback: ReactNode;
	children: JSX.Element;
	client: CoordinationClientType;
	messageId: string;
};

type State = {
	shouldRenderChildren: boolean;
};

// eslint-disable-next-line @repo/internal/react/no-class-components
export default class Coordination extends PureComponent<Props, State> {
	static defaultProps = {
		fallback: null,
	};

	state = {
		shouldRenderChildren: false,
	};

	async componentDidMount() {
		const { client, messageId } = this.props;
		try {
			const started = await client.start(messageId);
			if (started) {
				// eslint-disable-next-line react/no-did-mount-set-state
				this.setState({ shouldRenderChildren: true });
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(`Unable to start flow message ${messageId}. ${err}`);
		}
	}

	stop = async () => {
		const { client, messageId } = this.props;
		try {
			await client.stop(messageId);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(`Unable to stop flow message ${messageId}. ${err}`);
		}
	};

	injectStop(children: JSX.Element): JSX.Element {
		return cloneElement(children, { stop: this.stop });
	}

	render() {
		const { fallback, children } = this.props;
		const { shouldRenderChildren } = this.state;

		return shouldRenderChildren ? this.injectStop(children) : fallback;
	}
}
